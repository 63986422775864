import { render, staticRenderFns } from "./PromotionAdd.vue?vue&type=template&id=a00e705e&scoped=true&"
import script from "./PromotionAdd.vue?vue&type=script&lang=js&"
export * from "./PromotionAdd.vue?vue&type=script&lang=js&"
import style0 from "./PromotionAdd.vue?vue&type=style&index=0&id=a00e705e&lang=scss&scoped=true&"
import style1 from "./PromotionAdd.vue?vue&type=style&index=1&id=a00e705e&lang=scss&scoped=true&"
import style2 from "./PromotionAdd.vue?vue&type=style&index=2&id=a00e705e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a00e705e",
  null
  
)

export default component.exports