<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-card>
          <b-card-header><b class="h1">Basic Information</b></b-card-header>
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="*Promotion name:" label-cols-md="3">
                      <validation-provider
                        #default="{ errors }"
                        name="Promotion name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="model.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Promotion name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group
                      label="*Discount promotion period::"
                      label-cols-md="3"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Discount promotion period:"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="model.date_from_to"
                          class="form-control"
                          :config="{
                            mode: 'range',
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:ss',
                          }"
                          placeholder="Choose a date"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Active:" label-cols-md="3">
                      <b-form-checkbox
                        v-model="model.is_active"
                        checked="true"
                        class="custom-control-success"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="6">
                <b-form-group label="*Avatar:" label-cols-md="2">
                  <div
                    class="d-flex align-items-center justify-content-center"
                    style="max-width: 100%;"
                  >
                    <b-aspect aspect="2:1" style="width:100%;">
                      <b-img-lazy
                        :src="model.avatar"
                        fluid
                        thumbnail
                        rounded
                        style="position: absolute; left: 0;"
                      />
                      <div
                        class="fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"
                      >
                        <b-form-file
                          accept=".jpg, .png, .gif"
                          class="w-100 h-100 d-flex align-items-center justify-content-center img-box"
                          @change="previewFiles"
                        />
                        <b-button
                          variant="outline-primary"
                          class="btn-icon rounded-circle btn-add"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                      </div>
                    </b-aspect>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-body>
            <b-row>
              <b-col md="6">
                <b-form-group label="*Discount amount:" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Discount amount"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.percent"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Discount amount"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-card>
          <b-card-body>
            <b-row>
              <b-col md="12">
                <b-form-group label="Product for promotion:" label-cols-md="3">
                  <b-form-radio v-model="model.apply_all_product" :value="1">
                    All of product
                  </b-form-radio>
                  <div class="d-flex align-items-center">
                    <b-form-radio
                      v-model="model.apply_all_product"
                      :value="0"
                      class="mt-2"
                    >
                      Numbers of products
                    </b-form-radio>
                    <b-button
                      v-b-modal.modal-xl
                      variant="primary"
                      type="button"
                      class="ml-3"
                      :disabled="model.apply_all_product === 1 ? true : false"
                    >
                      Add product
                    </b-button>
                    <PopupAddProduct
                      v-model="model"
                      :productList="productList"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-3">
                <!-- <ProductList /> -->
                <b-table
                  striped
                  hover
                  responsive
                  borderless
                  :items="model.list_product"
                  :fields="fields"
                >
                  <!-- A virtual column -->
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(avatar)="item">
                    <b-avatar :src="item.item.avatar" />
                  </template>
                  <template #cell(name)="item">
                    <b-link :to="`detail/${item.item.id}`">
                      {{ item.item.name }}
                    </b-link>
                  </template>

                  <template #cell(is_active)="item">
                    <ActiveStatus :value="item.item.is_active" />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end mt-2">
            <b-button variant="outline-danger" type="button">
              Cancel
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="ml-2"
              @click.prevent="validationForm"
            >
              Confirm
            </b-button>
          </div>
        </b-col>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCardHeader,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormFile,
  BImgLazy,
  BAspect,
  BTable,
  BFormCheckbox,
  BFormRadio,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupAddProduct from './components/PopupAddProduct.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BForm,
    BFormFile,
    BImgLazy,
    BAspect,
    flatPickr,
    BFormCheckbox,
    BFormRadio,
    BAvatar,
    BLink,
    BTable,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    PopupAddProduct,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      productList: {
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        fields: [
          { key: 'checkbox', label: '#', sortable: true },
          { key: 'no', label: 'No', sortable: true },
          { key: 'avatar', label: 'Avatar', sortable: true },
          { key: 'name', label: 'Name', sortable: true },
          { key: 'sku', label: 'SKU', sortable: true },
          { key: 'price', label: 'Price', sortable: true },
        ],
      },
      required,
      model: {
        avatar: null,
        name: null,
        percent: null,
        is_active: true,
        date_from_to: null,
        date_from: null,
        date_to: null,
        list_product: [],
        content: null,
        apply_all_product: 0,
      },
      fields: [
        { key: 'checkbox', label: '#', sortable: true },
        { key: 'no', label: 'No', sortable: true },
        { key: 'avatar', label: 'Avatar', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'sku', label: 'SKU', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
      ],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_products?template_id=${templateId}&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.productList.perPage = res.data.data.count_page
            this.productList.currentPage = res.data.data.current_page
            this.productList.totalRows = res.data.data.items.length
            this.productList.items = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      const params = {
        template_id: JSON.parse(localStorage.getItem('templateID')).id,
        name: this.model.name,
        percent: this.model.percent,
        is_active: Number(this.model.is_active === true ? 1 : 0),
        date_from: this.model.date_from,
        date_to: this.model.date_to,
        content: 'Test',
        apply_all_product: this.model.apply_all_product,
      }
      if (this.model.date_from_to !== null) {
        const dateFromTo = this.model.date_from_to.split(' to ')
        params.date_from = dateFromTo[0]
        params.date_to = dateFromTo[1]
      }
      if (this.model.avatar !== null) {
        params.avatar = this.model.avatar
      }
      if (
        this.model.apply_all_product === 0 &&
        this.model.list_product &&
        this.model.list_product.length > 0
      ) {
        params.list_id_product = []
        this.model.list_product.map(item => {
          params.list_id_product.push(item.id)
        })
      }
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/platform_promotion`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Promotion success',
                  },
                })
                this.$router.push('/promotion/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Login fail',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
