<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div v-if="productList && productList.items && productList.items.length > 0">
    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      borderless
      :per-page="productList.perPage"
      :current-page="productList.currentPage"
      :items="productList.items"
      :fields="productList.fields"
      :sort-by.sync="productList.sortBy"
      :sort-desc.sync="productList.sortDesc"
      :sort-direction="productList.sortDirection"
    >
      <template #cell(checkbox)="item">
        <b-form-checkbox v-model="value.list_product" :value="item.item" />
      </template>
      <!-- A virtual column -->
      <template #cell(no)="item">
        {{ item.index + 1 }}
      </template>
      <template #cell(avatar)="item">
        <b-avatar :src="item.item.avatar" />
      </template>
      <template #cell(name)="item">
        <b-link :to="`detail/${item.item.id}`">
          {{ item.item.name }}
        </b-link>
      </template>

      <template #cell(is_active)="item">
        <ActiveStatus :value="item.item.is_active" />
      </template>
    </b-table>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="productList.perPage"
            size="sm"
            :options="productList.pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="10" sm="8" class="my-1">
        <b-pagination
          v-model="productList.currentPage"
          :total-rows="productList.totalRows"
          :per-page="productList.perPage"
          align="center"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <no-data />
  </div>
</template>
<script>
/* eslint-disable array-callback-return */
import {
  BLink,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormSelect,
  BTable,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import NoData from '@/views/components/nodata/index.vue'
import ActiveStatus from '@/views/components/active-status/index.vue'

export default {
  components: {
    BLink,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormSelect,
    BTable,
    BRow,
    BCol,
    NoData,
    ActiveStatus,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    categoryID: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    productList: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    handlerSelect() {
      this.$emit('handlerSelect', this.selectedList)
    },
    // async loadList(categoryID) {
    //   const templateId = JSON.parse(localStorage.getItem('templateID')).id
    //   try {
    //     const res = await Request.get(
    //       this.$http,
    //       `${process.env.VUE_APP_API_URL}/platform_products?template_id=${templateId}&category_id=${categoryID}`,
    //     )
    //     if (res.status === 200) {
    //       if (res.data.status) {
    //         this.perPage = res.data.data.count_page
    //         this.currentPage = res.data.data.current_page
    //         this.totalRows = res.data.data.items.length
    //         this.items = res.data.data.items
    //       } else {
    //         this.$toast({
    //           component: ToastificationContent,
    //           position: 'top-right',
    //           props: {
    //             title: 'Login fail',
    //             icon: 'AlertOctagonIcon',
    //             variant: 'danger',
    //             text: String(this.showError(res.data.error, ',')),
    //           },
    //         })
    //       }
    //     }
    //   } catch (error) {
    //     this.$toast({
    //       component: ToastificationContent,
    //       position: 'top-right',
    //       props: {
    //         title: 'Opps! Something wrong',
    //         icon: 'AlertOctagonIcon',
    //         variant: 'danger',
    //         text: String(error),
    //       },
    //     })
    //   }
    // },
  },
}
</script>
