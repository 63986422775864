<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    title="Add product to list promotion"
    @ok="handlerSubmit"
  >
    <b-row>
      <b-col md="3">
        <ProductCategorySort
          :list="items"
          @handlerChangeCategory="handlerChangeCategory"
        />
      </b-col>
      <b-col md="9">
        <ProductList
          v-model="value"
          :categoryID="categoryID"
          :productList="productList"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    BRow,
    BCol,
    ProductCategorySort,
    ProductList,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    productList: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      categoryID: null,
      items: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    handlerChangeCategory(data) {
      this.categoryID = data.id
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_category/tree?template_id=${templateId}&limit=1000`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.items = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
